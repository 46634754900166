.mouse-wheel {
  cursor: pointer;
}

.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #fff;
  border-radius: 12px;
  position: relative;
}

.scroll {
  width: 5px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%)
}